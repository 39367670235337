var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('the-breadcrumbs'),_c('page-content',{attrs:{"page-title":"Veranstalter"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.$store.getters['auth/isSuperAdmin'])?_c('region-chooser',{on:{"region:chosen":_vm.handleRegionChange}}):_vm._e(),_c('el-button',{attrs:{"type":"primary","icon":"fal fa-plus"},on:{"click":_vm.newOrganization}},[_vm._v(" Neuer Veranstalter ")])]},proxy:true}])},[_c('el-input',{staticStyle:{"max-width":"500px"},attrs:{"placeholder":"Durchsuchen…","clearable":""},model:{value:(_vm.computedSearchQuery),callback:function ($$v) {_vm.computedSearchQuery=$$v},expression:"computedSearchQuery"}},[_c('i',{staticClass:"el-icon-search el-input__icon",attrs:{"slot":"prefix"},slot:"prefix"})]),_c('data-loader',{ref:"dataloader",attrs:{"endpoint":("/admin/support_organizations?page=" + _vm.currentPage + "&query=" + _vm.remoteSearchQuery + "&region_id=" + (_vm.$store.state.auth.chosenRegion))},scopedSlots:_vm._u([{key:"loaded",fn:function(ref){
var data = ref.data;
return [_c('el-table',{staticStyle:{"width":"100%"},attrs:{"empty-text":"Keine Ergebnisse","stripe":"","data":data.support_organizations}},[_c('el-table-column',{attrs:{"prop":"name","label":"Name"}}),_c('el-table-column',{attrs:{"label":"Veranstaltungen"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.num_upcoming_events > 0)?_c('router-link',{staticClass:"el-link el-link--primary is-underline",attrs:{"to":{
                  name: 'EventsPage',
                  query: { organizationID: scope.row.id }
                }}},[_vm._v(" "+_vm._s(scope.row.num_upcoming_events)+" ")]):_c('span',[_vm._v(_vm._s(scope.row.num_upcoming_events))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Letzter Login"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.last_login_at)?_c('span',[_c('el-tag',{attrs:{"size":"small","type":_vm.isOlderThanSixMonths(scope.row.last_login_at)
                      ? 'warning'
                      : 'success'}},[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(scope.row.last_login_at), "dd.LL.yyyy"))+" ")])],1):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"ohne Prüfung eintragen"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.trusted === false)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v(" Nein ")]):_c('el-tag',{attrs:{"size":"small","type":"warning"}},[_vm._v(" Ja ")])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"contact_person_name","label":"Kontaktperson"}}),_c('el-table-column',{attrs:{"label":"Aktionen","width":"260"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"icon":"fal fa-edit","size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.row)}}},[_vm._v(" Bearbeiten ")]),_c('delete-button',{attrs:{"on-delete":_vm.deleteOrganization,"subject":"Veranstalter"},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('el-button',{attrs:{"size":"mini","icon":"fal fa-trash-alt","type":"danger"},on:{"click":function($event){return slotProps.handleDelete(scope.row)}}},[_vm._v(" Löschen ")])]}}],null,true)})]}}],null,true)})],1),_c('the-pagination',{attrs:{"total-count":data.total_count},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }